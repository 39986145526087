import React, { createContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../redux/app';
import { setSymbolInfo } from '../../redux/reducer/instruments';
import { setPayload } from '../../redux/reducer/payload';
import { checkMinMaxVolume } from '../../utilities/instrumentInfo';
import { openPosition } from '../../api/trade';
import { Notification } from '../../utilities/alert';
import { typeExec } from './TradeFunction';
import moment from 'moment';
import { calcValume } from '@vt/vt-trading-utils';

export const TradeContext = createContext('');

const isDst = () => {
  return moment.tz('Europe/London').isDST();
};

const TradeContextProvider = ({ children }) => {
  const dispatch = useAppDispatch();
  const instrumentInfo = useAppSelector((state) => state.symbol?.instrumentInfo);
  const payload = useAppSelector((state) => state.payload?.payload);
  const priceTrigerRef = useRef(null);
  const { active } = useSelector(({ symbol }) => symbol);
  const priceRef = useRef(null);
  const lotRef = useRef(null);
  const [pre, setPre] = useState(active);
  const [changeFunc, setChangeFunc] = useState(false);
  const [lotSize, setLotSize] = useState(0.01);

  useEffect(() => {
    dispatch(
      setPayload({
        ...payload,
        action: 200,
        digits: 0,
        price_sl: 0,
        price_tp: 0,
        symbol: instrumentInfo?.symbol,
        type: 0,
        type_fill: 0,
        volume: 100,
        price_order: 0,
        price_trigger: 0,
        account_trade_id: localStorage.getItem('iuxtrade.active.account')
      })
    );
  }, [instrumentInfo]);

  useEffect(() => {
    if (active !== pre || changeFunc) {
      setPre(active);
      setChangeFunc(false);
      dispatch(
        setPayload({
          ...payload,
          action: 200,
          digits: 0,
          price_sl: 0,
          price_tp: 0,
          symbol: null,
          type: 0,
          type_fill: 0,
          volume: 100,
          price_order: 0,
          price_trigger: 0,
          account_trade_id: localStorage.getItem('iuxtrade.active.account')
        })
      );
    }
    dispatch(setSymbolInfo(active));
  }, [active, changeFunc]);

  const handleLotSize = async (params) => {
    const data = await calcValume({
      lotSize: +lotSize,
      type: params,
      volume_max: instrumentInfo?.volume_max,
      volume_step: instrumentInfo?.volume_step
    });
    setLotSize(data);
  };

  const inputLotSize = (value) => {
    if (value === '') {
      setLotSize(value);
    } else {
      let regx = new RegExp(/^\d*\.?\d*$/);
      let val = value;
      if (!regx.test(value)) {
        return;
      } else {
        if (val.split('.')[1]?.length >= 2) {
          setLotSize((+val).toFixed(2));
        } else {
          setLotSize(val);
        }
      }
    }
  };

  const validateLotSize = (value) => {
    if (checkMinMaxVolume('min', +value, instrumentInfo?.volume_min).con) {
      setLotSize(checkMinMaxVolume('min', +value, instrumentInfo?.volume_min).value);
    }
    if (checkMinMaxVolume('max', +value, instrumentInfo?.volume_max).con) {
      setLotSize(checkMinMaxVolume('max', +value, instrumentInfo?.volume_max).value);
    }
  };

  const handleExecute = async (type, action, fill, price) => {
    const timeUnix = isDst() ? moment().unix() + 3600 : moment().unix();
    let isPayload = {};
    const timeExpire = payload.type_time === 1 ? timeUnix : payload.time_expiration;
    const lotSizeVolume = parseInt((lotSize * 10000).toFixed(0));
    if (price) {
      isPayload = {
        ...payload,
        type,
        action,
        price,
        digits: instrumentInfo?.digit,
        volume: lotSizeVolume,
        time_expiration: timeExpire
      };
    } else {
      isPayload = {
        ...payload,
        type,
        action,
        type_fill: fill,
        digits: instrumentInfo?.digit,
        volume: lotSizeVolume,
        time_expiration: timeExpire
      };
    }
    try {
      const { data } = await openPosition(isPayload);
      dispatch(setPayload({ ...payload, time_expiration: payload.time_expiration }));
      Notification({
        title: typeExec[type],
        message: data.data.message,
        type: 'success'
      });
    } catch ({ response }) {
      Notification({
        message: response.data.error.message,
        type: 'danger'
      });
    }
  };

  return (
    <TradeContext.Provider
      value={{
        instrumentInfo,
        handleLotSize,
        inputLotSize,
        validateLotSize,
        handleExecute,
        setLotSize,
        setChangeFunc,
        priceTrigerRef,
        priceRef,
        lotSize,
        lotRef,
        active
      }}>
      {children}
    </TradeContext.Provider>
  );
};

export default TradeContextProvider;
