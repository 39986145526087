import { getMenuFromLocalstorage } from '../utilities/fromStorage.js';
import { serverApiConfig } from '../config.js';
import SubscribeOnTick from './onTickSubscribe.js';
import { metadata } from './metadata.js';
import { periodRange } from './datafeed.v2.js';
import { OHLCV2Request } from '../grpc/generated/history_pb';
import { useHistoryData } from './useHistoryData';
import emitter from '../utilities/emitter';
import {
  configureCustomResolution,
  configureDailyResolution,
  configureMonthlyResolution,
  configureWeeklyResolution,
  handleDateShifting
} from '../utilities/datafeed.js';
import { RESOLUTION_CONFIG } from '../constant';

const moment = require('moment-timezone');
const { SymbolClient } = require('../grpc/generated/symbol_grpc_web_pb.js');

const { SymbolName } = require('../grpc/generated/symbol_pb.js');
const { TickRequest } = require('../grpc/generated/symbol_pb.js');
const { OHLCRequest } = require('../grpc/generated/history_pb');

const lastBarsCache = new Map();
const onSubscribe = new SubscribeOnTick();

let symbol = '';

function getSymbolFromURL() {
  const active = getMenuFromLocalstorage();
  const a = active?.active;
  return a;
}

function getInstruments() {
  const fromStorage = localStorage.getItem('symbols.trading.data');
  let data = JSON.parse(fromStorage) ?? [];
  return data;
}

const configurationData = {
  supports_search: true,
  supports_group_request: false,
  supports_marks: true,
  supports_timescale_marks: true,
  supports_time: true,
  supported_resolutions: ['1', '5', '15', '30', '60', '240', '1D', '1W', '1M'],
  exchanges: [
    {
      value: 'iux',
      name: 'iux',
      desc: 'IUX Markets Exchange'
    }
  ],
  symbols_types: [
    {
      name: 'crypto',
      value: 'crypto'
    }
  ]
};

let previousSymbol = null;
let listArray = [];

function createHistoryRequest(symbolInfo, resolution, periodParams) {
  const request = new OHLCV2Request();
  const { from, to } = handleDateShifting(
    request,
    periodParams.from,
    periodParams.to,
    resolution
  );

  // Configure based on resolution
  if (resolution.includes(RESOLUTION_CONFIG.DAILY)) {
    configureDailyResolution(request, periodParams.countBack);
  } else if (resolution.includes(RESOLUTION_CONFIG.WEEKLY)) {
    configureWeeklyResolution(request);
  } else if (resolution.includes(RESOLUTION_CONFIG.MONTHLY)) {
    configureMonthlyResolution(request);
  } else {
    configureCustomResolution(request, resolution, periodParams.countBack);
  }
  request.setSymbol(symbolInfo.name);
  request.setFrom(from);
  request.setTo(to);
  return request;
}

export default function ({ onSubscribe: { onSubscribe }, getLastTick }) {
  return {
    onReady: async (callback) => {
      // console.log('[onReady]: Method call');
      symbol = getSymbolFromURL();
      previousSymbol = getSymbolFromURL();
      listArray = getInstruments();
      setTimeout(() => callback(configurationData));
    },

    searchSymbols: async (userInput, exchange, symbolType, onResultReadyCallback) => {
      // console.log('searchSymbols');
      const symbols = [];
      const newSymbols = symbols.filter((symbol) => {
        const isExchangeValid = exchange === '' || symbol.exchange === exchange;
        const isFullSymbolContainsInput =
          symbol.full_name.toLowerCase().indexOf(userInput.toLowerCase()) !== -1;
        return isExchangeValid && isFullSymbolContainsInput;
      });
      onResultReadyCallback(newSymbols);
    },

    resolveSymbol: async (
      symbolName,
      onSymbolResolvedCallback,
      onResolveErrorCallback,
      extension
    ) => {
      try {
        let item = listArray.find((e) => e.symbol === symbolName);
        let priceScale = 10 ** item?.digit;
        const symbolInfo = {
          ticker: item?.full_name,
          name: item?.symbol,
          description: item?.description,
          type: item?.type,
          session: '24x7',
          timezone: 'Europe/London',
          exchange: item?.exchange,
          minmov: 1,
          pricescale: priceScale,
          has_intraday: true,
          // visible_plots_set: true,
          // intraday_multipliers: ['1', '5', '15', '30', '60', '240', '1D', '1W', '1M'],
          // supports_group_request: true,
          supported_resolutions: configurationData.supported_resolutions
          // volume_precision: 2
        };
        setTimeout(() => {
          onSymbolResolvedCallback(symbolInfo);
        }, 0);
      } catch (error) {
        console.log('error', error);
        setTimeout(() => {
          onResolveErrorCallback('cannot resolve symbol');
        }, 0);
      }
    },

    getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
      try {
        const request = createHistoryRequest(symbolInfo, resolution, periodParams);
        await useHistoryData({
          onHistoryCallback,
          request,
          resolution,
          periodParams,
          symbol: symbolInfo.name,
          firstDataRequest: periodParams.firstDataRequest,
          lastBarsCache
        });
      } catch (error) {
        console.log('[getBars]: Get error', error);
        setTimeout(() => {
          onErrorCallback(error);
        }, 0);
      }
    },

    subscribeBars: async (
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscribeUID,
      onResetCacheNeededCallback
    ) => {
      const request = new TickRequest();
      const symbol = new SymbolName();
      getLastTick(symbolInfo.name);
      symbol.setName(symbolInfo.name);
      request.addSymbols(symbol);

      onSubscribe(
        request,
        metadata(),
        resolution,
        onRealtimeCallback,
        symbolInfo.name,
        lastBarsCache,
        previousSymbol
      );

      emitter.instance.on('bar', (data) => {
        if (data.u[9] === symbolInfo.name) {
          let key = symbolInfo.name + resolution;
          const lastDailyBar = lastBarsCache.get(key);
          if (lastDailyBar !== undefined) {
            let bar = {};
            const time = periodRange(resolution, data.u[0]) * 1000;
            if (time > lastDailyBar.timeStamp) {
              bar = {
                open: data.u[1],
                high: data.u[1],
                low: data.u[1],
                close: data.u[1],
                time: time
              };
            } else {
              bar = {
                open: lastDailyBar.open,
                high: Math.max(lastDailyBar.high, data.u[1]),
                low: Math.max(lastDailyBar.low, data.u[1]),
                close: data.u[1],
                time: lastDailyBar.timeStamp
              };
            }
            lastBarsCache.set(key, { ...bar, timeStamp: bar.time });
            setTimeout(() => {
              onRealtimeCallback(bar);
            }, 0);
          }
        }
      });
    },

    unsubscribeBars: (subscriberUID) => {
      let symbolClient = new SymbolClient(
        serverApiConfig(localStorage.getItem('trade.server')),
        null,
        null
      );
      const symbolUnsub = subscriberUID.slice(0, subscriberUID.indexOf('_'));
      const key = symbolUnsub + subscriberUID.split('#')[1].replace('_', '');
      if (symbolUnsub !== previousSymbol) {
        // console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID);
        const request = new SymbolName();
        request.setName(symbolUnsub);
        symbolClient.tickUnsubscribe(request, metadata(), (err, response_) => {
          if (response_ === null) {
            return err;
          } else {
            let lastbar = lastBarsCache.get(key);
            if (lastbar !== undefined) {
              lastBarsCache.delete(key);
            }
          }
        });
      }
    }
  };
}

function toTimeZone(time, zone) {
  var format = 'YYYY/MM/DD HH:mm ZZ';
  var dateTime = moment(time, format).tz(zone).add(1, 'h').toDate();

  const timestampSecond = dateTime.getTime() / 1000;
  return timestampSecond;
}

function toTimeZone2(time, zone, resolution) {
  var format = 'YYYY/MM/DD HH:mm ZZ';
  if (resolution == '1') {
    var dateTime = moment(time, format).tz(zone).add(-5, 'd').toDate();
    const timestampSecond = dateTime.getTime() / 1000;
    return timestampSecond;
  } else if (resolution == '5') {
    let dateTime = moment(time, format).tz(zone).add(-3, 'd').toDate();
    const timestampSecond = dateTime.getTime() / 1000;
    return timestampSecond;
  } else if (resolution == '15') {
    let dateTime = moment(time, format).tz(zone).add(-5, 'd').toDate();
    const timestampSecond = dateTime.getTime() / 1000;
    return timestampSecond;
  } else if (resolution == '30') {
    let dateTime = moment(time, format).tz(zone).add(-10, 'd').toDate();
    const timestampSecond = dateTime.getTime() / 1000;
    return timestampSecond;
  } else if (resolution == '60') {
    let dateTime = moment(time, format).tz(zone).add(-15, 'd').toDate();
    const timestampSecond = dateTime.getTime() / 1000;
    return timestampSecond;
  } else if (resolution == '240') {
    let dateTime = moment(time, format).tz(zone).add(-30, 'd').toDate();
    const timestampSecond = dateTime.getTime() / 1000;
    return timestampSecond;
  } else if (resolution == '1D') {
    let dateTime = moment(time, format).tz(zone).add(-365, 'd').toDate();
    const timestampSecond = dateTime.getTime() / 1000;
    return timestampSecond;
  }
}
