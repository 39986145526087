import moment from 'moment';
import { TIME_UNITS } from '../constant';
import { shiftDST, shouldShift } from '../data/datafeed.v2';

export const calculateLimitFromDate = () => {
  return moment()
    .utc()
    .add(-(TIME_UNITS.SECONDS_PER_DAY * 365 * 10), 'seconds')
    .startOf('day')
    .unix();
};

export const handleDateShifting = (request, from, to, resolution) => {
  if (shouldShift(resolution)) {
    const shiftedFrom = shiftDST(from);
    const shiftedTo = shiftDST(to);
    request.setFrom(moment.unix(shiftedFrom).add(-TIME_UNITS.DAYS_SHIFT, 'days').unix());
    return { from: shiftedFrom, to: shiftedTo };
  }

  const limitFrom = calculateLimitFromDate();
  const adjustedFrom = from < limitFrom ? limitFrom : from;
  request.setFrom(adjustedFrom);
  return { from: adjustedFrom, to };
};

export const configureDailyResolution = (
  request,
  countBack
) => {
  request.setCountBack(
    Math.min(countBack, TIME_UNITS.MAX_DAYS_BACK)
  );
  request.setPeriod(1);
  request.setTimeFrame(TIME_UNITS.SECONDS_PER_DAY);
};

// Configure weekly resolution
export const configureWeeklyResolution = (request) => {
  request.setPeriod(7);
  request.setTimeFrame(TIME_UNITS.SECONDS_PER_DAY);
};

// Configure monthly resolution
export const configureMonthlyResolution = (request) => {
  request.setPeriod(30);
  request.setTimeFrame(TIME_UNITS.SECONDS_PER_DAY);
};

// Configure custom resolution
export const configureCustomResolution = (
  request,
  resolution,
  countBack
) => {
  request.setCountBack(countBack);
  const period = parseInt(resolution);
  
  if (period >= 60) {
    request.setPeriod(period / 60);
    request.setTimeFrame(TIME_UNITS.SECONDS_PER_HOUR);
  } else {
    request.setPeriod(period);
    request.setTimeFrame(TIME_UNITS.SECONDS_PER_MINUTE);
  }
};