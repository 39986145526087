import './index.css';
import React, { memo, useEffect } from 'react';
import emitter from '../../utilities/emitter';
import { useAppSelector } from '../../redux/app';
import { CreatePolisionLine } from './position';
import { CreateOrdersLine } from './order';
import { TemplateService } from '@vt/vt-trading-utils';

const { SymbolName } = require('../../grpc/generated/symbol_pb');
export const USER_ID_CHART = 'chart_iuxtrade';
const getSymbol = (chartSymbol) => {
  const temp = chartSymbol.split(':');
  const symbol = temp[temp.length - 1];
  window?.webkit?.messageHandlers?.symbolChanged?.postMessage(symbol);
  return symbol;
};

function loadTemplate(user_id, symbol) {
  let templateData = localStorage.getItem(user_id);

  let listTemplate = JSON.parse(templateData);
  if (listTemplate != null) {
    const indexOfObject = listTemplate.findIndex((object) => {
      return object?.symbol === symbol;
    });

    if (indexOfObject != -1) {
      let saved_data = listTemplate[indexOfObject]['template'];
      return saved_data;
    }
  } else {
    return null;
  }
}

function saveTemplate(user_id, symbol, saveObject) {
  let userData = localStorage.getItem(user_id);
  if (userData == null) {
    let listTemplate = [];
    let template = {
      symbol: symbol,
      template: saveObject
    };
    listTemplate.push(template);
    localStorage.setItem(user_id, JSON.stringify(listTemplate));
  } else {
    let listTemplate = JSON.parse(userData);
    const indexOfObject = listTemplate.findIndex((object) => {
      return object?.symbol === symbol;
    });

    if (indexOfObject !== -1) {
      listTemplate.splice(indexOfObject, 1);
    }

    let template = {
      symbol: symbol,
      template: saveObject
    };

    listTemplate.push(template);

    localStorage.setItem(user_id, JSON.stringify(listTemplate));
  }
}

const ChartBackground = memo(({ tvWidget }) => {
  const accountId = localStorage.getItem('iuxtrade.active.account')
  const active = useAppSelector((state) => state.symbol.active);
  const positions = useAppSelector(({ positions }) => positions?.positions);
  const orders = useAppSelector(({ orders }) => orders?.orders);
  const requestName = new SymbolName();

  useEffect(() => {
    let temp = null;
    if (active) {
      temp = active.toString();
    }
    if (tvWidget) {
      tvWidget.onChartReady(() => {
        CreatePolisionLine(positions, tvWidget);
        CreateOrdersLine(orders, tvWidget);
        tvWidget.subscribe('onAutoSaveNeeded', function () {
          tvWidget.save(function (saveObject) {

            let symbol = saveObject['charts'][0]['panes'][0]['sources'][0]['state']['symbol'];
            TemplateService.save(USER_ID_CHART, symbol, saveObject, accountId);
          });
        });
        const currentSymbol = getSymbol(tvWidget.activeChart().symbol());
        requestName.setName(currentSymbol);
        if (currentSymbol !== temp) {
          tvWidget.activeChart().setSymbol(temp);
        }
        emitter.instance.on('TickData', (data) => {
          try {
            let activeSymbol = tvWidget.activeChart().symbolExt().symbol;
            let line = [];
            if (data[activeSymbol]) {
              const shapes = tvWidget.activeChart().getAllShapes();
              if (shapes.length > 0) {
                shapes
                  .filter((shape) => shape.name === 'horizontal_line')
                  .forEach((shape) => {
                    const entity = tvWidget.activeChart().getShapeById(shape.id);
                    if (entity) {
                      const text = entity.getProperties()['text'];
                      if (text === 'bid') {
                        entity.setPoints([
                          { time: Date.now() / 1000, price: data[activeSymbol].bid }
                        ]);
                      } else if (text === 'ask') {
                        entity.setPoints([
                          { time: Date.now() / 1000, price: data[activeSymbol].ask }
                        ]);
                      }
                      if (!line.includes('ask') || !line.includes('bid')) {
                        line.push(entity.getProperties()['text']);
                      }
                    }
                  });
                if (!line.includes('ask') || !line.includes('bid')) {
                  const now = Date.now() / 1000;
                  tvWidget.activeChart().createShape(
                    { time: now, price: data[activeSymbol].ask },
                    {
                      shape: 'horizontal_line',
                      disableSelection: true,
                      disableSave: true,
                      disableUndo: true,
                      showInObjectsTree: false,
                      text: 'ask',
                      zOrder: 'top',
                      overrides: {
                        showLabel: false,
                        showTime: false,
                        linestyle: 1,
                        linecolor: '#DE5D4E'
                      }
                    }
                  );
                  tvWidget.activeChart().createShape(
                    { time: now, price: data[activeSymbol].bid },
                    {
                      shape: 'horizontal_line',
                      disableSelection: true,
                      disableSave: true,
                      disableUndo: true,
                      showInObjectsTree: false,
                      text: 'bid',
                      zOrder: 'top',
                      overrides: {
                        showLabel: false,
                        showTime: false,
                        linestyle: 1,
                        linecolor: '#4993F5'
                      }
                    }
                  );
                }
              } else {
                const now = Date.now() / 1000;
                tvWidget.activeChart().createShape(
                  { time: now, price: data[activeSymbol].ask },
                  {
                    shape: 'horizontal_line',
                    disableSelection: true,
                    disableSave: true,
                    disableUndo: true,
                    showInObjectsTree: false,
                    text: 'ask',
                    zOrder: 'top',
                    overrides: {
                      showLabel: false,
                      showTime: false,
                      linestyle: 1,
                      linecolor: '#DE5D4E'
                    }
                  }
                );
                tvWidget.activeChart().createShape(
                  { time: now, price: data[activeSymbol].bid },
                  {
                    shape: 'horizontal_line',
                    disableSelection: true,
                    disableSave: true,
                    disableUndo: true,
                    showInObjectsTree: false,
                    text: 'bid',
                    zOrder: 'top',
                    overrides: {
                      showLabel: false,
                      showTime: false,
                      linestyle: 1,
                      linecolor: '#4993F5'
                    }
                  }
                );
              }
            }
          } catch (e) {
            return e;
            // window.location.reload();
          }
        });
      });
    }
  }, [tvWidget, active, positions, orders]);

  return <div />;
});
ChartBackground.displayName = 'ChartBackground';
export default ChartBackground;
