import React, { createContext, useEffect, useReducer, useState } from 'react';
import { signInWithCustomToken, signInWithEmailAndPassword } from 'firebase/auth';
import { accountTradeApi, firebaseCustomApi, getMe } from '../api/account';
import { AuthClient } from '../grpc/generated/auth_grpc_web_pb';
import { TokenRequest } from '../grpc/generated/auth_pb';
import { Notification } from './alert';
import { auth, logoutFirebase } from '../firebase';
import ListArray from '../data/listArray';
import { StorageService } from './storageService';
import { serverApiConfig } from '../config';
import { handleTokenResponse } from './authService';

let google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');

const initialState = {
  isAuthenticated: false,
  isInitialized: true,
  user: null,
  accounts: [],
  activeAccount: null,
  isOtp: false,
  token: false
};

const authReducer = (state, action) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        ...state,
        ...action.payload
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        isInitialized: false,
        user: null,
        accounts: null,
        activeAccount: null,
        isOtp: false
      };
    default:
      return state;
  }
};

// services/tokenService.ts
class TokenService {
  static async getTokenFromAuth(working, index, token, data) {
    const authClient = new AuthClient(serverApiConfig(working[index]?.api_server), null, null);

    const tokenRequest = new TokenRequest();
    tokenRequest.setMainToken(token);
    tokenRequest.setAccountTradeId(working[index].id);

    return new Promise((resolve) => {
      authClient.getToken(tokenRequest, {}, async (err, response) => {
        if (err) {
          const errorState = await this.handleTokenError(working, index);
          resolve(errorState);
        } else {
          const successState = await handleTokenResponse(response, working, index, data);
          resolve(successState);
        }
      });
    });
  }

  static async handleTokenError(working, index) {
    if (working.length > index) {
      return null; // Will trigger retry
    }

    Notification({
      message: 'Token request failed',
      type: 'danger'
    });

    return {
      isAuthenticated: false,
      isInitialized: false,
      accounts: null,
      activeAccount: null
    };
  }
}

// services/errorHandler.ts
class AuthErrorHandler {
  static async handleAuthError(error, logout, state, dispatch) {
    if (error.response?.data?.code === 1010) {
      dispatch({
        type: 'INITIALIZE',
        payload: {
          ...state,
          isInitialized: false,
          isOtp: true
        }
      });
      return;
    }

    const errorMessages = {
      'auth/user-not-found':
        'Incorrect email or password. Please check your credentials and try again.',
      'auth/wrong-password':
        'Incorrect email or password. Please check your credentials and try again.',
      'auth/invalid-email':
        'Incorrect email or password. Please check your credentials and try again.'
    };

    const message = errorMessages[error.code] || 'Something went wrong';

    Notification({
      message,
      type: 'danger'
    });

    await logout();
  }
}

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  authWithToken: () => Promise.resolve(),
  getFirebaseCustom: () => Promise.resolve()
});

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const [index, setIndex] = useState(0);
  const listArray = new ListArray();
  const request = new google_protobuf_empty_pb.Empty();

  const initialize = async (token, data) => {
    const tk = StorageService.getItem('trade.token');
    const server = StorageService.getItem('trade.server');
    const acc = StorageService.getItem('iuxtrade.active.account');

    try {
      if (!server) {
        StorageService.setItem('trade.server', data.data[0]?.api_server);
      }
      if (!acc) {
        StorageService.setItem('iuxtrade.active.account', data.data[0]?.id);
      }

      let newState;
      if (tk) {
        const me = await getMe();
        await listArray.getListArray(request, { Authorization: 'Bearer ' + tk });
        newState = {
          isAuthenticated: true,
          isInitialized: false,
          accounts: data.data,
          activeAccount: acc,
          user: me.data
        };
      } else {
        const working = data.data?.sort((a, b) => b.current_balance - a.current_balance) ?? [];

        if (working.length > 0) {
          newState = await TokenService.getTokenFromAuth(working, index, token, data);
          if (!newState) {
            setIndex((prev) => prev + 1);
            return;
          }
        } else {
          newState = {
            isAuthenticated: true,
            isInitialized: false,
            accounts: [],
            activeAccount: null
          };
        }
      }

      dispatch({
        type: 'INITIALIZE',
        payload: newState
      });
    } catch (error) {
      console.error('Initialize error:', error);
      await logout();
      dispatch({
        type: 'INITIALIZE',
        payload: {
          ...state,
          isInitialized: false
        }
      });
    }
  };

  const handleTradeData = async (user, tradeData) => {
    if (!tradeData?.data.length) {
      dispatch({
        type: 'INITIALIZE',
        payload: {
          ...state,
          isInitialized: false
        }
      });
      Notification({
        message: 'Account trade not found',
        title: 'Invalid',
        type: 'danger'
      });
      await logout();
      return;
    }

    const token = await user.getIdToken();
    await initialize(token, tradeData);
  };

  const login = async ({ username, password, setLoading }) => {
    try {
      await signInWithEmailAndPassword(auth, username, password);
      setLoading(false);
    } catch (error) {
      await AuthErrorHandler.handleAuthError(error, logout, state, dispatch);
      setLoading(false);
    }
  };

  const authWithToken = async ({ token, setLoading }) => {
    if (setLoading) {
      setLoading(true);
    }
    if (state.isOtp) {
      await logout();
    }
    try {
      localStorage.removeItem('trade.token');
      localStorage.removeItem('iuxtrade.active.account');
      dispatch({
        type: 'INITIALIZE',
        payload: {
          ...state,
          isInitialized: true
        }
      });
      await signInWithCustomToken(auth, token);
      const { data } = await accountTradeApi();
      await handleTradeData(auth.currentUser, data);
    } catch (error) {
      console.error('Error signing in:', error);
      await logout();
      if (setLoading) {
        setLoading(false);
      }
    }
  };

  const getFirebaseCustom = async () => {
    try {
      const { data } = await firebaseCustomApi();
      return data.data.token;
    } catch (error) {
      return undefined;
    }
  };

  const logout = async () => {
    await logoutFirebase();
    localStorage.removeItem('trade.token');
    dispatch({ type: 'LOGOUT' });
    if (window.electron) {
      window.electron?.store.delete('token');
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      try {
        if (!user) {
          dispatch({
            type: 'INITIALIZE',
            payload: state
          });
          await logout();
          return;
        }
        const { data } = await accountTradeApi();
        await handleTradeData(user, data);
      } catch (error) {
        await AuthErrorHandler.handleAuthError(error, logout, state, dispatch);
      }
    });

    return () => unsubscribe();
  }, [index]);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        authWithToken,
        getFirebaseCustom
      }}>
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
