import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import './index.css';
import './App.css';
import './animation.css';
import './СomponentStyle.styl';
import 'react-notifications-component/dist/theme.css';
import store from './redux/store';
import { BrowserRouter } from 'react-router-dom';
import { ReactNotifications } from 'react-notifications-component';
import { AuthProvider } from './utilities/JWTContext';
import Router from './routes';
import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div className="App">
    <AuthProvider>
      <ReactNotifications />
      <Provider store={store}>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </Provider>
    </AuthProvider>
  </div>
);

// ลงทะเบียน service worker
// eslint-disable-next-line no-undef
if ('serviceWorker' in navigator) {
  // ใช้ได้ทั้งใน development และ production
  window.addEventListener('load', () => {
    const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
    // eslint-disable-next-line no-undef
    navigator.serviceWorker
      .register(swUrl)
      .then((registration) => {
        // ตรวจสอบการอัปเดต
        registration.onupdatefound = () => {
          const installingWorker = registration.installing;
          if (installingWorker == null) {
            return;
          }
          installingWorker.onstatechange = () => {
            if (installingWorker.state === 'installed') {
              // eslint-disable-next-line no-undef
              if (navigator.serviceWorker.controller) {
                console.log(
                  'New content is available and will be used when all tabs for this page are closed.'
                );

                // แสดงข้อความแจ้งเตือนให้ผู้ใช้รีเฟรชหน้า
                if (
                  window.confirm(
                    'New content is available. Would you like to reload the page to use it?'
                  )
                ) {
                  window.location.reload();
                }
              } else {
                console.log('Content is cached for offline use.');
              }
            }
          };
        };
      })
      .catch((error) => {
        console.error('Error during service worker registration:', error);
      });
  });
}
