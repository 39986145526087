import React, { memo, useEffect, useState } from 'react';
import { setPayload } from '../../redux/reducer/payload';
import { useAppDispatch, useAppSelector } from '../../redux/app';

const Expiration = memo(() => {
  const payload = useAppSelector((state) => state.payload?.payload);
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);

  const [select, setSelect] = useState(0);

  useEffect(() => {
    if (select < 2) {
      dispatch(setPayload({ ...payload, type_time: select, time_expiration: 0 }));
    } else {
      dispatch(setPayload({ ...payload, type_time: select }));
    }
  }, [select]);

  const List = ['GTC', 'Today', 'Specified', 'Specified Day'];
  
  return (
    <div className="select-expire flex items-center px-3 relative cursor-pointer">
      <div className="text-white text-sm w-full" onClick={() => setOpen(true)}>{List[select]}</div>
      {open && (
        <div
          onMouseLeave={() => setOpen(false)}
          onClick={() => setOpen(false)}
          className="absolute top-[42px] left-0 bg-[#1E1E1E] border border-[#80808059] w-full flex flex-col rounded-md z-10">
          {List.map((e, i) => (
            <div
              onClick={() => {
                setOpen(false);
                setSelect(i)
              }}
              key={e}
              className="text-white text-sm px-4 h-[30px] flex items-center hover:bg-[#169376] hover:rounded-md transition-all cursor-pointer">
              {e}
            </div>
          ))}
        </div>
      )}
      {/* <select
        className="dark"
        onChange={({ target: { value } }) => {
          setSelect(+value)
        }}>
        <option value={0} selected={select === 0}>GTC</option>
        <option value={1} selected={select === 1}>Today</option>
        <option value={2} selected={select === 2}>Specified</option>
        <option value={3} selected={select === 3}>Specified Day</option>
      </select> */}
    </div>
  );
});
Expiration.displayName = 'Expiration';

export default Expiration;
