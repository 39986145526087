import React, { useContext, useEffect, useState } from 'react'
import { DropDownContext } from './provider'
import { useAppSelector } from '../../redux/app'
import emitter from '../../utilities/emitter'
import moment from 'moment'
import calculatePercentageChange from '../../helpers/calculatePercentageChange';

const Spread = ({ item }) => {
  const stat = useAppSelector((state) => state?.stat?.stat)
  const tickStore = useAppSelector((state) => state?.stat?.tick)
  const [tick, setTickData] = useState(tickStore ?? {})
  const { symbolObj, category } = useContext(DropDownContext)

  useEffect(() => {
    emitter.instance.on('TickData', (data) => {
      symbolObj[category].map((e) => {
        if (data[e.symbol]) {
          setTickData((prev) => ({
            ...prev, [e.symbol]: data[e.symbol],
          }))
        }
      })
    })
  }, [symbolObj, category])

  return (<div className="flex flex-[0.5]">
      <div style={{ flex: '0.54' }}>{tick[item?.symbol]?.bid?.toFixed(item?.digit)}</div>
      <div style={{ flex: '0.46' }}>
        <div className="flex items-center relative">
          <span className={calculatePercentageChange(tick, item, stat) ?? ''}>
            {calculatePercentageChange(tick, item, stat) !== 0 ? (<svg
                className={tick ? (calculatePercentageChange(tick, item, stat) > 0 ? '' : 'rotate-90') : ''}
                width="12"
                height="12"
                fill={tick ? calculatePercentageChange(tick, item, stat) > 0 ? '#1f6fde' : '#C94644' : ''}
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
                transform="rotate(270)"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  <path
                    d="M16.172 9l-6.071-6.071 1.414-1.414L20 10l-.707.707-7.778 7.778-1.414-1.414L16.172 11H0V9z"></path>
                </g>
              </svg>) : (<div>
                <svg
                  className="relative bottom-[4px]"
                  width="12"
                  height="12"
                  viewBox="0 -4.5 20 20"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  fill="#8a8c97"
                  transform="rotate(-45)"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                  <g id="SVGRepo_iconCarrier">
                    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <g
                        id="Dribbble-Light-Preview"
                        transform="translate(-300.000000, -6643.000000)"
                        fill="#8a8c97"
                      >
                        <g id="icons" transform="translate(56.000000, 160.000000)">
                          <polygon
                            id="arrow_right-[#346]"
                            points="264 6488.26683 258.343 6483 256.929 6484.21678 260.172 6487.2264 244 6487.2264 244 6489.18481 260.172 6489.18481 256.929 6492.53046 258.343 6494"
                          ></polygon>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
                <svg
                  className="rotate-[135deg] absolute bottom-[1px]"
                  width="12"
                  height="12"
                  viewBox="0 -4.5 20 20"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  fill="#8a8c97"
                  transform="rotate(-45)"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                  <g id="SVGRepo_iconCarrier">
                    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <g
                        id="Dribbble-Light-Preview"
                        transform="translate(-300.000000, -6643.000000)"
                        fill="#8a8c97"
                      >
                        <g id="icons" transform="translate(56.000000, 160.000000)">
                          <polygon
                            id="arrow_right-[#346]"
                            points="264 6488.26683 258.343 6483 256.929 6484.21678 260.172 6487.2264 244 6487.2264 244 6489.18481 260.172 6489.18481 256.929 6492.53046 258.343 6494"
                          ></polygon>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>)}
          </span>
          <span
            className={`ml-1 font-[400] ${calculatePercentageChange(tick, item, stat) === 0 ? 'text-[gray]' : calculatePercentageChange(tick, item, stat).toFixed(2) > 0 ? 'text-[#1f6fde]' : 'text-[#cb543c]'}`}
          >
            {`${calculatePercentageChange(tick, item, stat).toFixed(2)}%`}
          </span>
        </div>
        <div className="text-white flex items-center">
          <div className="mr-1 text-[#8a8c97] font-[500] text-xs min-w-[15px]">
            {tick ? (isNaN((tick[item.symbol]?.ask - tick[item.symbol]?.bid) * Math.pow(10, item.digit)) ? (
                <span>...</span>) : (((tick[item.symbol]?.ask - tick[item.symbol]?.bid) * Math.pow(10, item.digit)).toFixed(0))) : (0)}
          </div>
          <div className="mr-1">
            <svg
              width="11.999999999999998"
              height="11.999999999999998"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <title>Layer 1</title>
                <g id="svg_18">
                  <line
                    stroke="#8a8c97"
                    strokeLinecap="undefined"
                    strokeLinejoin="undefined"
                    id="svg_15"
                    y2="4.87279"
                    x2="10.03537"
                    y1="4.87279"
                    x1="1.77771"
                    fill="none"
                  />
                  <line
                    stroke="#8a8c97"
                    strokeLinecap="undefined"
                    strokeLinejoin="undefined"
                    id="svg_16"
                    y2="1.71117"
                    x2="1.98415"
                    y1="4.74455"
                    x1="1.98415"
                    fill="none"
                  />
                  <line
                    stroke="#8a8c97"
                    strokeLinecap="undefined"
                    strokeLinejoin="undefined"
                    id="svg_17"
                    y2="1.71117"
                    x2="9.96534"
                    y1="4.74455"
                    x1="9.96534"
                    fill="none"
                  />
                </g>
                <g transform="rotate(180 6.06303 8.92576)" id="svg_14">
                  <line
                    stroke="#8a8c97"
                    strokeLinecap="undefined"
                    strokeLinejoin="undefined"
                    id="svg_4"
                    y2="10.50657"
                    x2="10.19186"
                    y1="10.50657"
                    x1="1.9342"
                    fill="none"
                  />
                  <line
                    stroke="#8a8c97"
                    strokeLinecap="undefined"
                    strokeLinejoin="undefined"
                    id="svg_13"
                    y2="7.34496"
                    x2="2.14064"
                    y1="10.37833"
                    x1="2.14064"
                    fill="none"
                  />
                  <line
                    stroke="#8a8c97"
                    strokeLinecap="undefined"
                    strokeLinejoin="undefined"
                    id="svg_9"
                    y2="7.34496"
                    x2="10.12183"
                    y1="10.37833"
                    x1="10.12183"
                    fill="none"
                  />
                </g>
              </g>
            </svg>
          </div>
          <div className="text-[#8a8c97] font-[500] text-xs">
            {moment(tick[item.symbol]?.datetime_msc).utc().format('HH:mm:ss')}
          </div>
        </div>
      </div>
    </div>)
}

export default Spread
