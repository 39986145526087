import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { Login } from '../components/Login';
import AuthGuard from '../guards/AuthGuard';
import Dashboard from '../components/Dashboard';
import GuestGuard from '../guards/GuestGuard';
import Register from '../components/Register';

export default function Router() {
  const routes = [
    {
      path: 'login/:token',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      )
    },
    {
      path: 'login',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      )
    },
    {
      path: 'register',
      element: (
        <GuestGuard>
          <Register />
        </GuestGuard>
      )
    },
    {
      path: 'trade-panel',
      element: (
        <AuthGuard>
          <Dashboard />
        </AuthGuard>
      )
    },
    {
      path: '*',
      element: <Navigate to="/login" replace />
    }
  ];

  return useRoutes(routes);
}
