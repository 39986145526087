const calculatePercentageChange = (tick, item, stat) => {
  // Early return if required parameters are missing
  if (!tick || !item?.symbol || !stat) {
    return 0;
  }

  const symbol = item.symbol;
  const currentBid = tick[symbol]?.bid;
  const previousClose = stat[symbol]?.price_close;

  // Return 0 if either price is missing or invalid
  if (!currentBid || !previousClose || previousClose === 0) {
    return 0;
  }

  const percentageChange = ((currentBid - previousClose) / previousClose) * 100;

  // Round to 2 decimal places and ensure we return a number
  return Number.isFinite(percentageChange) ? Number(percentageChange.toFixed(2)) : 0;
};

export default calculatePercentageChange;