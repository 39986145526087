import React, { useContext, useEffect } from 'react';
import { TradeContext } from '../components/trad-dialog/TradeContextProvider';
const LotSizeController = ({ setError }) => {
  const { lotSize, inputLotSize, validateLotSize, instrumentInfo, setLotSize } =
    useContext(TradeContext);

  const min = instrumentInfo.volume_step / 10000;
  const max = instrumentInfo.volume_max / 10000;

  useEffect(() => {
    if (lotSize !== '' && lotSize > 0) {
      if (lotSize >= max) {
        setLotSize(max);
      }
    }
    if (lotSize !== '' && lotSize < 0) {
      if (lotSize <= min) {
        setLotSize(min);
      }
    }
  }, [lotSize, max, min]);

  return (
    <div className="flex items-center lotsize-manual">
      <div
        className="step_01 text-white"
        onClick={() => {
          if (lotSize > min) {
            setLotSize((prev) => prev - instrumentInfo.volume_step / 1000);
          }
        }}>
        {`-${instrumentInfo.volume_step / 1000}`}
      </div>
      <div
        className={`step__01 text-white`}
        onClick={() => {
          if (lotSize > min) {
            setLotSize((prev) => prev - instrumentInfo.volume_step / 10000);
          }
        }}>
        {`-${instrumentInfo.volume_step / 10000}`}
      </div>
      <div className="step text-white border-b border-b-[#149377]">
        <input
          value={lotSize.toLocaleString()}
          onChange={({ target: { value } }) => inputLotSize(value)}
          onBlur={({ target: { value } }) => validateLotSize(value)}
          type="text"
        />
      </div>
      <div
        className="step__01 text-white"
        onClick={() => {
          if (lotSize < max) {
            setLotSize((prev) => prev + instrumentInfo.volume_step / 10000);
          } else if (lotSize >= max) {
            setLotSize(max);
          }
        }}>
        +{instrumentInfo.volume_step / 10000}
      </div>
      <div
        className="step_01 text-white"
        onClick={() => {
          if (lotSize < max) {
            setLotSize((prev) => prev + instrumentInfo.volume_step / 1000);
          } else if (lotSize >= max) {
            setLotSize(max);
          }
        }}>
        +{instrumentInfo.volume_step / 1000}
      </div>
    </div>
  );
};

export default LotSizeController;
