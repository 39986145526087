import { serverApiConfig } from '../config';
import EventEmitter from '../utilities/service';
const { SymbolClient } = require('../grpc/generated/symbol_grpc_web_pb.js');

export default class ListArray {
  async getListArray(request, metadata) {
    let symbolClient = new SymbolClient(
      serverApiConfig(localStorage.getItem('trade.server')),
      null,
      null
    );
    await symbolClient.listArrayNew(request, metadata, (err, response_) => {
      if (response_ === null) {
        setTimeout(() => {
          console.log('reconnect');
          // window.location.reload();
        }, 5000);
        return err;
      } else {
        let allSymbols = [];
        response_.u[0].forEach((symbolData) => {
          let symbol = {
            symbol: symbolData[84],
            full_name: symbolData[84],
            exchange: 'IUX',
            description: symbolData[84],
            type: symbolData[59].split('\\')[1],
            digit: symbolData[17],
            company_name: symbolData[16],
            logo: symbolData[103],
            currency_margin: symbolData[12],
            contract_size: symbolData[8],
            volume_max: symbolData[95],
            volume_min: symbolData[97],
            volume_step: symbolData[99],
            stops_level: symbolData[77] ?? 0,
            spread: symbolData[73],
            exec_mode: symbolData[19] ?? 0,
            stat: {
              price_open: 0,
              bid_high: 0
            }
          };
          allSymbols.push(symbol);
        });
        // console.log('allSymbols', allSymbols)
        const a = allSymbols;
        localStorage.setItem('symbols.trading.data', JSON.stringify(a));
        EventEmitter.dispatch('listArray', a);
      }
    });
  }
}
