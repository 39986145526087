import React, { memo, useEffect, useMemo, useState } from 'react';
import { SymbolItem } from './SymbolItem';
import { setMenuSymbol, setSymbolActive } from '../redux/reducer/instruments';
import AccountInfo from './AccountInfo';
import DropDownPanel from './dropdown';
import useAuth from '../hooks/useAuth';
import DropDownProvider from './dropdown/provider';
import { useAppDispatch, useAppSelector } from '../redux/app';
import { setDropdown } from '../redux/reducer/dropdown';
import { origin } from './Login';

const typeServer = { api: 'Live' };
const accountType = { Standard: 'Standard', StandardBonus: 'Standard+', Cent: 'Cent' };

const getOriginUrl = (originUrl) => {
  return origin[originUrl];
};

const CustomScrollTopMenu = memo(() => {
  const { activeAccount } = useAuth();
  const { menu } = useAppSelector(({ symbol }) => symbol);
  const { open } = useAppSelector(({ dropdown }) => dropdown);
  const [sizeEle, setSizeEle] = useState(undefined);

  const currentMenu = useMemo(() => {
    return menu[activeAccount];
  }, [menu]);
  const dispatch = useAppDispatch();

  const handleClick = (type, value, symbol, obj) => {
    if (type === 'select') {
      dispatch(setSymbolActive(obj.symbol));
    }
    if (type === 'close') {
      const lastActive = currentMenu.menu.slice(0, value);
      if (value === currentMenu.menu.length - 1) {
        dispatch(
          setMenuSymbol({
            type: 'del',
            data: { symbol: symbol },
            active: lastActive[value - 1].symbol
          })
        );
        dispatch(setSymbolActive(lastActive[value - 1].symbol));
      } else {
        dispatch(
          setMenuSymbol({
            type: 'del',
            data: { symbol: symbol },
            active: currentMenu.active
          })
        );
        dispatch(setSymbolActive(currentMenu.active));
      }
    }
  };

  const toggle = (el) => {
    dispatch(setDropdown(el));
  };

  useEffect(() => {
    if (!sizeEle) {
      setSizeEle(
        setTimeout(() => {
          var max_beaver_width = document.querySelector('#customScrollTopMenu');
          setSizeEle(max_beaver_width?.offsetWidth);
        }, 1000)
      );
    } else {
      const body = document.getElementsByTagName('body');
      if (body.length > 0) {
        // eslint-disable-next-line no-undef
        const resizeObserver = new ResizeObserver((e) => {
          if (e.length > 0) {
            var max_beaver_width = document.querySelector('#customScrollTopMenu');
            setSizeEle(max_beaver_width?.offsetWidth);
          }
        });
        resizeObserver.observe(body[0]);
        return () => {
          resizeObserver.disconnect();
        };
      }
    }
  }, [sizeEle]);

  // console.log('sizeEle', currentMenu.menu.length * 120 + 56, sizeEle)

  return (
    currentMenu && (
      <div
        id="customScrollTopMenu"
        className="flex items-center pl-1 w-[calc(100vw_-_497px)] overflow-x-auto h-[65px]">
        <div className={`flex gap-2 h-[45px]`}>
          {currentMenu.menu.map((e, index) => (
            <div
              key={index}
              // onClick={() => start()}
              style={{
                transition: 'all 0.3s ease-in'
              }}
              className={`relative ${index === 0 && 'ml-0'} ${
                currentMenu.menu.length * 120 + 56 > sizeEle ? '-ml-[50px]' : '-ml-0'
              }`}>
              <SymbolItem
                lastIndex={currentMenu?.menu?.length === 1}
                symbol={e.symbol}
                handleClick={handleClick}
                keyIndex={index}
                active={e.symbol === currentMenu?.active}
                logo={e.logo}
                length={currentMenu.menu.length}
                maxW={currentMenu.menu.length * 120 + 56 > sizeEle}
              />
            </div>
          ))}
        </div>
        <div className="relative">
          <button
            className="btn-add flex justisfy-center items-center"
            onClick={() => {
              toggle(!open);
            }}>
            <svg
              className="m-auto"
              width="15px"
              height="15px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                {' '}
                <g id="Edit / Add_Plus">
                  {' '}
                  <path
                    id="Vector"
                    d="M6 12H12M12 12H18M12 12V18M12 12V6"
                    stroke="#fff"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"></path>{' '}
                </g>{' '}
              </g>
            </svg>
          </button>
          {open && (
            <div
              className="fixed w-full h-full left-0 top-0 bg-[#0e0e0e00] z-[4]"
              onClick={() => {
                toggle(!open);
              }}></div>
          )}
          <DropDownProvider open={open}>
            {open && <DropDownPanel toggle={toggle} />}
          </DropDownProvider>
        </div>
        <div className="bg-[red] w-full h-full sidebar-draggable"></div>
      </div>
    )
  );
});
CustomScrollTopMenu.displayName = 'CustomScrollTopMenu';

const TopMenuAccountInfo = memo(() => {
  const { activeAccount, accounts, getFirebaseCustom, user } = useAuth();

  const account = useMemo(
    () =>
      accounts?.reduce((a, b) => {
        return {
          ...a,
          [b.id]: b
        };
      }, {}),
    [accounts]
  );

  if (!account) return;
  return (
    <div className="flex items-center mr-[14px]">
      <div className="border-r border-r-[#585858] px-[14px]">
        <div className="text-white flex items-center justify-start font-bold text-sm">
          <span className="mr-2">Account:</span>
          <span>{account[activeAccount]?.account_number}</span>
        </div>
        <div className="font-bold text-sm">
          <span
            className={`capitalize ${
              account[activeAccount]?.type === 'demo' ? 'text-[#ff6c00]' : 'text-[#169376]'
            }`}>
            {typeServer[account[activeAccount]?.api_server]
              ? typeServer[account[activeAccount]?.api_server]
              : account[activeAccount]?.api_server}
          </span>
          <span className="capitalize text-sm text-[#c1c1c1]">{` - ${
            accountType[account[activeAccount]?.account_type] ??
            account[activeAccount]?.account_type
          }`}</span>
        </div>
      </div>
      <AccountInfo />
      {account[activeAccount]?.type === 'real' && (
        <button
          className="deposit"
          onClick={() =>
            getFirebaseCustom().then((token) => {
              window.open(
                `${getOriginUrl(window.location.origin)}/en/wallet/fund/deposit/${
                  account[activeAccount]?.account_number
                }/${token}`
              );
            })
          }
          rel="noreferrer">
          <span className="text-space">DEPOSIT</span>
        </button>
      )}
    </div>
  );
});
TopMenuAccountInfo.displayName = 'TopMenuAccountInfo';

const TopPanel = () => {
  return (
    <div className="menu-top justify-between relative">
      <div className="fixed top-0 w-full h-2 sidebar-draggable" />
      <div className="flex">
        <a
          href={window.electron?.isApp ? `#` : `/`}
          className="sidebar-draggable flex w-[70px] relative">
          <div className="absolute w-full h-full hover:bg-[#26131353] bg-transparent transition-all" />
          <span className="m-auto">
            <img
              className="w-[48px] h-[46px] rounded"
              src="/images/iuxtrade/IUXTrade-icon.png"
              height="auto"
            />
          </span>
        </a>
        <CustomScrollTopMenu />
      </div>
      <TopMenuAccountInfo />
    </div>
  );
};

export default TopPanel;
