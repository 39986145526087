export const type = {
  str: {
    'buy': "Buy",
    'sell': "Sell",
    'editBuyLimit': "Buy Limit",
    'editSellLimit': "Sell Limit",
    'editBuyStop': "Buy Stop",
    'editSellStop': "Sell Stop",
    'editBuyStopLimit': "Buy Stop Limit",
    'editSellStopLimit': "Sell Stop Limit"
  }
};

export const FILTER_LIST = [
  {
    name: 'All',
    key: 6,
  },
  {
    name: 'Today',
    key: 7,
  },
  {
    name: 'Last Week',
    key: 0,
  },
  {
    name: 'Last Month',
    key: 1,
  },
  {
    name: 'Last 3 Month',
    key: 2,
  },
  {
    name: 'Last 6 Month',
    key: 3,
  },
  {
    name: 'Last Year',
    key: 4,
  },
  {
    name: 'Custom',
    key: 5,
  },
]

export const TIME_UNITS = {
  SECONDS_PER_DAY: 24 * 60 * 60,
  SECONDS_PER_HOUR: 60 * 60,
  SECONDS_PER_MINUTE: 60,
  MAX_DAYS_BACK: 3650, // 10 years
  DAYS_SHIFT: 5,
};

export const RESOLUTION_CONFIG = {
  DAILY: 'D',
  WEEKLY: 'W',
  MONTHLY: 'M',
};